import { HTMLAttributes, ReactNode, useRef, useState } from 'react';
import { default as Currency } from 'currency.js';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Text } from '@components/atoms/text/text';
import {
  IconArrowUp,
  IconBuild,
  IconChurch,
  IconTithe,
  IconWorld,
} from '@components/atoms/icons';
import { Button } from '@components/atoms/button/button';
import { PaymentCategoryType } from '@models/payment-category';
import { TitheFormTotals } from '@hooks/use-tithe-form';
import { HeadingUppercase } from '@components/atoms/heading-uppercase/heading-uppercase';
import { PaymentCurrency } from '@models/payment-schedule';

export type InViewItems = {
  [key in PaymentCategoryType]: boolean;
};
interface Props extends Omit<HTMLAttributes<HTMLElement>, 'onClick'> {
  isSubmitting: boolean;
  totals: TitheFormTotals;
  grandTotal: Currency;
  itemsInView: InViewItems;
  isRecurringUpdate: boolean;
  showCurrency: boolean;
  currency?: PaymentCurrency | null;
  error?: string;
  onClick?: (category: PaymentCategoryType) => void;
  onElementHeight?: (height: number) => void;
}

const messages = defineMessages({
  catTithe: {
    id: 'MM6X7o',
    description: 'Heading for "tithe"',
    defaultMessage: 'Tithe',
  },
  catLocal: {
    id: 'D5GsVI',
    description: 'Heading for local offerings',
    defaultMessage: 'Local Offerings',
  },
  catConf: {
    id: 'BNgxFv',
    description: 'Heading for conference offerings',
    defaultMessage: 'Conference/Union Offerings',
  },
  catWorld: {
    id: 'zD/vUf',
    description: 'Heading for world offerings',
    defaultMessage: 'World Offerings',
  },
});

interface CategoryOptions {
  intlKey: keyof typeof messages;
  category: PaymentCategoryType;
  icon: ReactNode;
}
const categories: CategoryOptions[] = [
  {
    intlKey: 'catTithe',
    category: 'tithe',
    icon: <IconTithe />,
  },
  {
    intlKey: 'catLocal',
    category: 'church',
    icon: <IconChurch color="nad-blue" />,
  },
  {
    intlKey: 'catConf',
    category: 'church.parent',
    icon: <IconBuild size="S" height={25} width={25} color="nad-blue" />,
  },
  {
    intlKey: 'catWorld',
    category: 'world',
    icon: <IconWorld color="nad-blue" width={22} height={24} size="S" />,
  },
];

const TitheEnvelopeTotals = ({
  totals,
  grandTotal,
  itemsInView,
  error,
  isSubmitting,
  isRecurringUpdate,
  showCurrency,
  currency,
  onClick,
  onElementHeight,
  ...props
}: Props) => {
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const categoryInView = categories.find((c) => itemsInView[c.category]);
  const mobileFixedRef = useRef<HTMLDivElement>(null);
  if (onElementHeight) {
    setTimeout(() => {
      onElementHeight(
        mobileFixedRef && mobileFixedRef.current
          ? mobileFixedRef.current.offsetHeight
          : 0
      );
    }, 100);
  }

  return (
    <div
      {...props}
      ref={mobileFixedRef}
      className={`w-full ${props.className}`}
    >
      <div className={`lg:px-8 xl:px-24 lg:pt-12 lg:pb-4`}>
        <Text
          as="h2"
          size="L"
          color="nad-blue"
          className="font-serif hidden lg:flex"
        >
          <FormattedMessage
            id="xbjBr+"
            defaultMessage="Jump to Category"
            description="Heading above categories that list out totals for each category."
          />
        </Text>
        <div
          className={`w-full lg:mt-6 ${
            isExpanded ? 'divide-y' : 'lg:divide-y'
          } divide-nad-alps-night-2-500`}
        >
          {categories.map((category) => (
            <div
              onClick={(e) => onClick && onClick(category.category)}
              key={category.category}
              className={`cursor-pointer flex gap-2 items-center w-full py-2 pr-16 lg:pr-0 lg:py-6 h-12 lg:h-auto relative overflow-hidden ${
                showCategories || isExpanded ? '' : 'hidden lg:flex'
              }`}
              data-testid="tithe-envelope-category"
            >
              {categoryInView &&
                categoryInView.category === category.category && (
                  <div className="absolute top-0 left-0 w-2 h-80 bg-nad-ming"></div>
                )}
              <div className="w-8 ml-3 flex justify-center">
                {category.icon}
              </div>
              <div className="flex-1">
                <HeadingUppercase
                  variant="h6"
                  color="nad-blue"
                  className="text-xs"
                >
                  {intl.formatMessage(messages[category.intlKey])}
                </HeadingUppercase>
              </div>
              <div className="text-right">
                <Text size="S" data-testid={`tithe-total-${category.category}`}>
                  {totals[category.category].format() || `$0`}
                </Text>
              </div>
            </div>
          ))}
        </div>
        <Text
          className={`px-6 py-2 text-center lg:p-0 lg:text-left lg:mt-2 ${
            error ? '' : 'hidden lg:block lg:invisible'
          }`}
          size="S"
          color="nad-scarlett"
        >
          {/* Word is there to intentionally provide height to element */}
          {error || 'hidden'}
        </Text>
      </div>
      <div className="lg:-mx-1">
        <div className="w-full bg-nad-blue-2 pb-8 lg:pb-4 p-4">
          <div
            className="flex justify-center items-center space-x-2 font-serif text-nad-white pt-1.5"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowCategories(!showCategories);
            }}
          >
            <div className="text-m lg:text-l cursor-pointer">
              <FormattedMessage
                id="2cmOwl"
                defaultMessage="Current Total"
                description="Label next to currency on the tithe envelope page. Total for all entires."
              />
              {showCurrency && currency && ` (${currency})`}:
            </div>
            <div
              className="text-m lg:text-xl cursor-pointer"
              data-testid="tithe-grand-total"
            >
              {grandTotal.format() || `$0`}
            </div>

            <Button
              className={`${showCategories ? '' : 'rotate-180'} lg:hidden`}
              type="button"
              variant="icon"
            >
              <IconArrowUp color="nad-white" />
            </Button>
          </div>
          <div className="flex justify-center py-2 lg:py-6">
            <Button
              isLoading={isSubmitting}
              disabled={isSubmitting || !!error}
              type="submit"
              variant="outline"
              icon="rightArrow"
              className="disabled:!border-white border-transparent bg-white hover:!bg-nad-ming disabled:hover:!bg-white"
            >
              {isRecurringUpdate && (
                <FormattedMessage
                  id="T9/mQI"
                  defaultMessage="Update Recurring Donation"
                  description="Main button that appears on tithe envelope page when updating a recurring donation."
                />
              )}
              {!isRecurringUpdate && (
                <FormattedMessage
                  id="kJEdvS"
                  defaultMessage="Continue To Donate"
                  description="Main form button that appears on tithe envelope page."
                />
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitheEnvelopeTotals;
