import { defineMessages, useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { Link } from '../../atoms/link/link';
import { Text } from '../../atoms/text/text';
import { IconArrowLeft } from '../../atoms/icons/arrow-left';
import { useCurrentUser } from '@hooks/use-user';

const messages = defineMessages({
  labelBack: {
    id: 'muyk+A',
    defaultMessage: 'Back',
    description: 'Label for "back" link.',
  },
});

interface Props {
  href?: string;
  label?: string;
}

const BackLink = ({ href, label = '' }: Props) => {
  const router = useRouter();
  const intl = useIntl();
  const backLink = router.query.back as string | undefined;
  const { data: user } = useCurrentUser();
  const isLoggedIn = user && user.status !== 'guest';
  href = href ?? backLink ?? (isLoggedIn ? '/account' : '/');
  return (
    <Link
      href={href}
      variant="uppercase"
      className="flex items-center justify-center py-2 space-x-3 w-fit"
      data-testid="back-link"
    >
      <IconArrowLeft color="nad-blue" />
      <Text size="S" color="nad-blue">
        {label ? label : intl.formatMessage(messages.labelBack)}
      </Text>
    </Link>
  );
};

export default BackLink;
